import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/login.vue')
  },
  {
    path: '/boxIndex',
    name: 'boxIndex',
    component: () => import('../views/boxIndex/boxIndex.vue')
  },
  {
    path: '/boxGoodsList',
    name: 'boxGoodsList',
    component: () => import('../views/boxGoodsList/boxGoodsList.vue')
  },
  {
    path: '/chance',
    name: 'chance',
    component: () => import('../views/chance/chance.vue')
  },
  {
    path: '/boxOpen',
    name: 'boxOpen',
    component: () => import('../views/boxOpen/boxOpen.vue')
  },
  {
    path: '/orderList',
    name: 'orderList',
    component: () => import('../views/orderList/orderList.vue')
  },
  {
    path: '/historyOrder',
    name: 'historyOrder',
    component: () => import('../views/historyOrder/historyOrder.vue')
  },
  {
    path: '/waitSign',
    name: 'waitSign',
    component: () => import('../views/waitSign/waitSign.vue')
  },{
    path: '/sign',
    name: 'sign',
    component: () => import('../views/sign/sign.vue')
  },{
    path: '/addressChoose',
    name: 'addressChoose',
    component: () => import('../views/addressChoose/addressChoose.vue')
  },{
    path: '/addressAddEdit',
    name: 'addressAddEdit',
    component: () => import('../views/addressAddEdit/addressAddEdit.vue')
  },{
    path: '/addressAdd',
    name: 'addressAdd',
    component: () => import('../views/addressAdd/addressAdd.vue')
  },{
    path: '/addressEdit',
    name: 'addressEdit',
    component: () => import('../views/addressEdit/addressEdit.vue')
  },{
    path: '/expressList',
    name: 'expressList',
    component: () => import('../views/expressList/expressList.vue')
  }, {
    path: '/expressDetailWaitting',
    name: 'expressDetailWaitting',
    component: () => import('../views/expressDetailWaitting/expressDetailWaitting.vue')
  },{
    path: '/expressDetailDelivered',
    name: 'expressDetailDelivered',
    component: () => import('../views/expressDetailDelivered/expressDetailDelivered.vue')
  },{
    path: '/expressDetailExchange',
    name: 'expressDetailExchange',
    component: () => import('../views/expressDetailExchange/expressDetailExchange.vue')
  },{
    path: '/exchangeGoods',
    name: 'exchangeGoods',
    component: () => import('../views/exchangeGoods/exchangeGoods.vue')
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.query.token) {
    localStorage.setItem('token', to.query.token)
  }
  next()
})

export default router
