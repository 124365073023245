import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const UPDATE_GOODLIST = 'UPDATE_GOODLIST'
const UPDATE_CUR_GOODLIST = 'UPDATE_CUR_GOODLIST'
const UPDATE_CHOOSE_ADDRESS = 'UPDATE_CHOOSE_ADDRESS'
const UPDATE_EDIT_ADDRESS = 'UPDATE_EDIT_ADDRESS'

const UPDATE_ORDER_NO = 'UPDATE_ORDER_NO'
const UPDATE_ORDER_INFO = 'UPDATE_ORDER_INFO'

export default new Vuex.Store({
  state: {
    goodsList: [], // 全部签发
    curGoodsList: [], // 当前选中签发
    chooseAddress: localStorage.getItem('chooseAddress') ? JSON.parse(localStorage.getItem('chooseAddress')) : '',
    editAddress: {}, // 当前编辑地址
    logistics_order_no: {},
    orderInfo: {}
  },
  getters: {
    getGoodsList: state => state.goodsList,
    getCurGoodsList: state => state.curGoodsList,
    getChooseAddress: state => state.chooseAddress,
    getEditAddress: state => state.editAddress,
    getOrderNo: state => state.logistics_order_no,
    getOrderInfo: state => state.orderInfo
  },
  mutations: {
    [UPDATE_GOODLIST] (state, list) {
      state.goodsList = list
    },
    [UPDATE_CUR_GOODLIST](state, list) {
      state.curGoodsList = list
    },
    [UPDATE_CHOOSE_ADDRESS] (state, obj) {
      state.chooseAddress = obj
      localStorage.setItem('chooseAddress', JSON.stringify(obj))
    },
    [UPDATE_EDIT_ADDRESS] (state, obj) {
      console.log(obj)
      state.editAddress = obj
    },
    [UPDATE_ORDER_NO] (state, str) {
      state.logistics_order_no = str
    },
    [UPDATE_ORDER_INFO](state, info) {
      state.orderInfo = info
    }
  },
  actions: {
  },
  modules: {
  }
})
